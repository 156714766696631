import { memo, useEffect } from 'react';
import withLoggedUser from '../WithLoggedUser';
import { withResponsive } from '@components/ResponsiveProvider';

const HubspotChat = memo(({ viewer, windowWidth }) => {
    const isMobile = windowWidth <= 768;
    useEffect(() => {
        if (viewer && viewer.role === 'customer' && process.env.NODE_ENV === 'production') {
                
            if (isMobile) {
                if (viewer && viewer.role === 'customer' && process.env.NODE_ENV === 'production') {
                    if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
                        const status = window.HubSpotConversations.widget.status();
                        if (status.loaded) {
                            window.HubSpotConversations.widget.remove();
                        }
                    }
                }
            } else {
                const { email, tokenHubspot } = viewer;

                if (tokenHubspot) {
                    window.hsConversationsSettings = {
                        identificationEmail: email,
                        identificationToken: tokenHubspot,
                    };
                }

                if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
                    window.HubSpotConversations.widget.load();
                }


                return () => {
                    if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
                        window.HubSpotConversations.widget.remove();
                    }
                };
            }
        }
       
    }, [viewer, isMobile]);

    return null;
});

export default withResponsive(withLoggedUser(HubspotChat));
